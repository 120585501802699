import React, {useState} from 'react';
import {auth, db} from '../config/Config';
import {Link,useNavigate} from 'react-router-dom';


export const Signup = (props) => {
  const [firstname, setFirstName] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('Vaofd');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [error,setError] = useState('');
  const navigate = useNavigate();

  const Signup = (e) =>{
    e.preventDefault();
    //console.log("formsubmitted");
    auth.createUserWithEmailAndPassword(email,password).then((cred)=> {
      db.collection('SignedUpUsersData').doc(cred.user.uid).set({
        FirstName:firstname,
        MiddleName:middlename,
        LastName:lastname,
        Email:email,
        Password:password,
        PhoneNumber:phonenumber,
        Company:'vaofd',
        Address1:address1,
        Address2:address2,
        Address3:address3,
        City:city,
        State:state,
        Pincode:pincode
      }).then(()=>{
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setPhoneNumber('');
        setCompany('Vaofd');
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setCity('');
        setState('');
        setPincode('');
        setError('');
        alert("You are successfully registered");
        navigate('/login');
      }).catch(err=>setError(err.message));
    }).catch(err=>setError(err.message));
  }
    return (
      <div className="container">
      <br />
      <h2>Sign up</h2>
      <hr />
          <br />
          <form autoComplete="off" className="form-group" onSubmit={Signup}>
            <label htmlFor="FirstName">First Name</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setFirstName(e.target.value)} value={firstname} />
            <br />
            <label htmlFor="MiddletName">Middle Name</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setMiddleName(e.target.value)} value={middlename} />
            <br />
            <label htmlFor="LastName">Last Name</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setLastName(e.target.value)} value={lastname} />
            <br />
            <label htmlFor="Email">Email</label>
            <br />
            <input type="email" required className="form-control" onChange={(e)=>setEmail(e.target.value)} value={email} />
            <br />
            <label htmlFor="Password">Password</label>
            <br />
            <input type="password" required className="form-control" onChange={(e)=>setPassword(e.target.value)} value={password} />
            <br />
            <label htmlFor="PhoneNumber">Phone Number</label>
            <br />
            <input type="number" required className="form-control" onChange={(e)=>setPhoneNumber(e.target.value)} value={phonenumber} />
            <br />
            <label htmlFor="Company">Company</label>
            <br />
            <input type="text" required readOnly="readOnly" className="form-control" onChange={(e)=>setCompany(e.target.value)} value={company} />
            <br />
            <label htmlFor="Address1">Address1</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setAddress1(e.target.value)} value={address1} />
            <br />
            <label htmlFor="Address2">Address2</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setAddress2(e.target.value)} value={address2} />
            <br />
            <label htmlFor="Address3">Address3</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setAddress3(e.target.value)} value={address3} />
            <br />
            <label htmlFor="City">City</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setCity(e.target.value)} value={city} />
            <br />
            <label htmlFor="State">State</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setState(e.target.value)} value={state} />
            <br />
            <label htmlFor="Pincode">Pincode</label>
            <br />
            <input type="number" required className="form-control" onChange={(e)=>setPincode(e.target.value)} value={pincode} />
            <br />
            <button type="submit" className="btn btn-success btn-md">Register</button>
          </form>
          {error && <span>{error}</span>}
          <br />
          <span>Already have an account? Login
            <Link to="/login"> Here</Link>
            </span>
      </div>
    )
}
