import React, { useLayoutEffect, useState,useContext,useEffect  } from "react";
import { useParams } from "react-router-dom";
import '../css/Home.css';
import '../css/ProductDetail.css';
import { Navbar } from './Navbar';
import { db } from '../config/Config'
import { ProductsContext } from '../global/ProductsContext'
import { CartContext } from '../global/CartContext'
//import { Products } from './Products';

import Footer from './Footer';

export const ProductDetail = ({ user }) => {
  //console.log(user);
  const { pid } = useParams();
  const [oneproduct, SetProducts] = useState({ });
  var product=[];
  const [ProductName, setProductName] = useState('');
  const [ProductPrice, setProductPrice] = useState('');
  const [ProductImg, setProductImg] = useState('');
  const { products } = useContext(ProductsContext);
  const { dispatch } = useContext(CartContext);
 const [filterProducts, setfilterProducts] = useState(products);
 const onFilterChangeHandler = (pid) =>{

     if(pid !== 'all'){
         let filteritems = products.filter(i=>{
             return i.ProductName === pid
         })
         console.log('filteritems: ',filteritems)
         if(filteritems.length === 0){
    //         alert('No Products to display');
         }else{
             setfilterProducts(filteritems)

         }
     }else{
         setfilterProducts(products);

     }
 }
  useEffect(() => {
    console.log("sssssss"+products);
    onFilterChangeHandler(pid);
    const fetch = async () => {
      try {

            db.collection('Products')
            .where("ProductName", "==", pid)
            .onSnapshot(snapshot => {
                let changes = snapshot.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                        const oneproduct={
                            "ProductName": change.doc.data().ProductName,
                            "ProductPrice": change.doc.data().ProductPrice,
                            "ProductImg": change.doc.data().ProductImg
                          }
                          setProductName(change.doc.data().ProductName);
                          setProductPrice(change.doc.data().ProductPrice);
                          setProductImg(change.doc.data().ProductImg);
                          SetProducts(oneproduct);
                          product.push({
                              "ProductName": change.doc.data().ProductName,
                              "ProductPrice": change.doc.data().ProductPrice,
                              "ProductImg": change.doc.data().ProductImg
                            });
                          console.log(oneproduct);
                    }
                    console.log(oneproduct);

                    console.log("es"+oneproduct.ProductName);
                  })
                })

              } catch (err) {
      console.error(err);
    }

  };
  fetch();
}, []);

    return (
      <>
      {ProductName.length !== 0 &&

      <div className="App page-wrapper">
        <header className='header'>
          <Navbar user={user} />
          </header>
          <main className='main'>
          <section className="pt-10 pb-10">

          <div className="container">
              <div className="card">
                <div className="container-fliud">
                  <div className="wrapper row">
                    <div className="preview col-md-6">

                      <div className="preview-pic tab-content">
                        <div className="tab-pane active" id="pic-1"><img src={ProductImg} /></div>
                        </div>


                    </div>
                    <div className="details col-md-6">
                      <h3 className="product-title">{ProductName}</h3>
                   {/*    <div className="rating">
                        <div className="stars">
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                       <span className="review-no">41 reviews</span>
                      </div>*/}
                      <p className="product-description">Suspendisse quos? Tempus cras iure temporibus? Eu laudantium cubilia sem sem! Repudiandae et! Massa senectus enim minim sociosqu delectus posuere.</p>
                      <h4 className="price">Price: <span>&#36; {ProductPrice}</span></h4>
                      <p className="vote"><strong>91%</strong> of buyers enjoyed this product! <strong>(87 votes)</strong></p>

                      <div className="action">
                        <button className='addcart-btn' onClick={() => dispatch({ type: 'ADD_TO_CART', id: product.ProductID, product })}>ADD TO CART</button>
                       {/*} <button className="like btn btn-default" type="button"><span className="fa fa-heart"></span></button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

          </section>
          </main>
          <footer className="footer appear-animate">
        <Footer />
      </footer>
      </div>
}

      </>
    )
}
