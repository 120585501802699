import React, { useContext, useEffect } from 'react'
import { CartContext } from '../global/CartContext'
import { Navbar } from './Navbar';
import { Icon } from 'react-icons-kit'
import { ic_add } from 'react-icons-kit/md/ic_add'
import { ic_remove } from 'react-icons-kit/md/ic_remove'
import { iosTrashOutline } from 'react-icons-kit/ionicons/iosTrashOutline'
import { Link,NavLink } from 'react-router-dom'
import { useNavigate  } from 'react-router-dom'
import { auth } from '../config/Config'
import '../css/Home.css';
import Footer from './Footer';

export const Cart = ({ user }) => {

  // Read data from cart context
    const { shoppingCart, dispatch, totalPrice, totalQty } = useContext(CartContext);

    const navigate = useNavigate();

    useEffect(() => {
        /*auth.onAuthStateChanged(user => {
            if (!user) {

                navigate('/login');
            }
        })*/
    })

    return (
        <>
        <div className="App page-wrapper">
          <header className='header'>
            <Navbar user={user} />
            </header>
            <main className='main'>
            <section className="pt-10 pb-10">
            <>
                {shoppingCart.length !== 0 && <h1>Your Cart</h1>}
                <div className='cart-container' >
                <div className="row">
                <div className="col-8">
                    {
                        shoppingCart.length === 0 && <>
                            <div>No items in your cart or You are not logged in</div>
                            <div><NavLink to="/">Return to Home page</NavLink></div>
                        </>
                    }
                    {shoppingCart && shoppingCart.map(cart => (
                      <div >
                        <div className='cart-card' key={cart.ProductID} >

                            <div className='cart-img'>
                                <img src={cart.ProductImg} alt="not found" />
                            </div>

                            <div className='cart-name'>{cart.ProductName}</div>

                            <div className='cart-price-orignal'>$ {cart.ProductPrice}.00</div>

                            <div className='inc' onClick={() => dispatch({ type: 'INC', id: cart.ProductID, cart })}>
                                <Icon icon={ic_add} size={24} />
                            </div>

                            <div className='quantity'>{cart.qty}</div>

                            <div className='dec' onClick={() => dispatch({ type: 'DEC', id: cart.ProductID, cart })}>
                                <Icon icon={ic_remove} size={24} />
                            </div>

                            <div className='cart-price'>
                                &#36; {cart.TotalProductPrice}.00
                            </div>

                            <button className='delete-btn' onClick={() => dispatch({ type: 'DELETE', id: cart.ProductID, cart })}>
                                <Icon icon={iosTrashOutline} size={24} />
                            </button>
                        </div>
                        <div className="clearfix"></div>
                        </div>
                    ))
                    } </div>
                    {shoppingCart.length > 0 &&<div className='cart-summary col-4'>
                        <div className='cart-summary-heading'>
                            Summary <span>({totalQty} item)</span>
                        </div>
                        <div className='cart-summary-price'>
                            <span>Sub Total</span>
                            <span>${totalPrice}.00</span>
                        </div>
                        <div className='cart-summary-price'>
                            <span>Shipping (5%)</span>
                            <span>${totalPrice*0.05}</span>
                        </div>

                        <div className='cart-summary-price'>
                            <span>Est. Taxes (15%)</span>
                            <span>${totalPrice*0.15}</span>
                        </div>

                        <div className='cart-summary-price'>
                            <span>Total</span>
                            <span>${totalPrice+(totalPrice*0.05)+(totalPrice*0.15)}</span>
                        </div>
                        <NavLink to='/cashout' className='cashout-link'>
                            <button className='btn btn-success btn-md' style={{ marginTop: 5 + 'px' }}>
                                Checkout
                        </button>
                        </NavLink>
                    </div>}
                    </div>
                </div>
            </>
            </section>
            </main>
            <footer className="footer appear-animate">
          <Footer />
        </footer>
        </div>
        </>
    )
}
