import CategorySwiper from './CategorySwiper';
import {Products} from './Products';
// import filter from '../assets/images/icons/filter_icon.png';
// import Button from 'react-bootstrap/Button';

import React, { useState } from 'react';
// import SlidingPanel from 'react-sliding-side-panel';
// import 'react-sliding-side-panel/lib/index.css';

function MainContent() {


    return (
    <>
        <CategorySwiper />
        <section className="pt-10 pb-10">
            <div className="container">
                  <div className="row">


                          <div className="col-md-9">
                                  <div className="row cols-xl-4 cols-md-4 cols-sm-3 cols-2 products_data">

                                                <Products />


                                  </div>
                          </div>

                  </div>
            </div>
        </section>       


    </>
    )
}

export default MainContent;
