import React from 'react';
import '../css/Home.css';
import { Navbar } from './Navbar';
//import { Products } from './Products';
import MainContent from './MainContent';
import Footer from './Footer';

export const Home = ({ user }) => {



    return (
      <>
      <div className="App page-wrapper">
        <header className='header'>
          <Navbar user={user} />
          </header>
          <main className='main'>
          <MainContent />
          </main>
          <footer className="footer appear-animate">
        <Footer />
      </footer>
      </div>


      </>
    )
}
