import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route,Routes,HashRouter } from "react-router-dom";
import { ProductsContextProvider } from './global/ProductsContext'
import { Home } from './components/Home';
import { AddProducts } from './components/AddProducts';
import { Signup } from './components/Signup';
import { Login } from './components/Login';
import { ProductDetail } from './components/ProductDetail';
import { auth, db } from './config/Config';
import { CartContextProvider } from './global/CartContext'
import { Cart } from './components/Cart'
import { Cashout } from './components/Cashout'
import { StaticRouter } from "react-router-dom/server";
import { ToastContainer } from "react-toastify";

export class App extends Component {



  state = {
      user: null,
  }

  componentDidMount() {

        // getting user info for navigation bar
        auth.onAuthStateChanged(user => {
            if (user) {
                db.collection('SignedUpUsersData').doc(user.uid).get().then(snapshot => {
                    this.setState({
                        user: snapshot.data().FirstName
                    })
                })
            }
            else {
                this.setState({
                    user: null
                })
            }
        })

    }

  render() {
    return (
      <HashRouter>
      <ToastContainer/>
      <div>
       <ProductsContextProvider>
        <CartContextProvider>
        <Routes>
          <Route exact path="/" element={<Home user={this.state.user} />}/>
          <Route path="/addproducts" element={<AddProducts/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path={"/productdetail/:pid"} element={<ProductDetail user={this.state.user}/>}/>
          <Route path="/cartproducts" element={<Cart user={this.state.user} />} />
          <Route path='/cashout' element={<Cashout user={this.state.user} />} />
        </Routes>
        </CartContextProvider>
        </ProductsContextProvider>
      </div>
    </HashRouter>
    )
  }
}
export default App
