import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {

  apiKey: "AIzaSyBatd3NV4bTSFl6O3ieSX9t0fAqED3YrL4",

  authDomain: "vaofd-9c121.firebaseapp.com",

  projectId: "vaofd-9c121",

  storageBucket: "vaofd-9c121.appspot.com",

  messagingSenderId: "1063182265367",

  appId: "1:1063182265367:web:a13b40a0f6f52c4da64052"

};


firebase.initializeApp(firebaseConfig);

const auth=firebase.auth();
const db=firebase.firestore();
const storage = firebase.storage();

export { auth, db, storage }
