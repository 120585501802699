import React, {useState} from 'react'
import { auth,db } from '../config/Config'
import  {Link,useNavigate } from 'react-router-dom'

export const Login = () => {

  //login page variables
    const [showLogin,setShowLogin] =useState(true);
    const [showGuest,setShowGuest] = useState(false);
    const [firstname, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

  //login firebase api
    const login = (e) => {
        e.preventDefault();
        auth.signInWithEmailAndPassword(email, password).then(() => {
            setEmail('');
            setPassword('');
            setError('');

            alert("You are succeffully logged in");
            navigate('/');
        }).catch(err => setError(err.message));
    }
    const Signup = (e) =>{
      e.preventDefault();
      //console.log("formsubmitted");
      auth.createUserWithEmailAndPassword(email,'123123').then((cred)=> {
        db.collection('SignedUpUsersData').doc(cred.user.uid).set({
          FirstName:firstname,
          Email:email,
          Password:'123123',
        }).then(()=>{
          setFirstName('');
          setEmail('');

          setError('');
          navigate('/cartproducts');
        }).catch(err=>setError(err.message));
      }).catch(err=>setError(err.message));
    }

    const showSGuest = () =>{
      setShowLogin(false);
      setShowGuest(true);
    }

    return (
      <div className='container'>
            <br />
            {showLogin &&
              <>
            <h2>Login</h2>
            <br />
            <form autoComplete="off" className='form-group' onSubmit={login}>
                <label htmlFor="email">Email</label>
                <input type="email" className='form-control' required
                    onChange={(e) => setEmail(e.target.value)} value={email} />
                <br />
                <label htmlFor="password">Password</label>
                <input type="password" className='form-control' required
                    onChange={(e) => setPassword(e.target.value)} value={password} />
                <br />
                <button type="submit" className='btn btn-success btn-md mybtn'>LOGIN</button>
            </form>
            </>
          }
          {showGuest &&
            <>
            <h2>Guest</h2>
            <form id="idGuest" autoComplete="off" className='form-group' onSubmit={Signup} >
            <label htmlFor="FirstName">First Name</label>
            <br />
            <input type="text" required className="form-control" onChange={(e)=>setFirstName(e.target.value)} value={firstname} />
            <br />
                <label htmlFor="email">Email</label>
                <input type="email" className='form-control' required
                    onChange={(e) => setEmail(e.target.value)} value={email} />
                <br />

                <button type="submit" className='btn btn-success btn-md mybtn'>GUEST</button>
            </form>
            </>
          }
            {error && <span className='error-msg'>{error}</span>}
            <br/>
            <span>Don't have an account? Register
                <Link to="/signup"> Here</Link>
            </span>
            <br/>
            <span>As a Guest, Please click
                <div onClick={showSGuest}> Here</div>
            </span>
        </div>
    )
}
