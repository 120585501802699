import React, { useState, useEffect, useContext } from 'react'
import { auth, db } from '../config/Config'
import { CartContext } from '../global/CartContext'
import { Navbar } from './Navbar';
import { useNavigate,NavLink } from 'react-router-dom'
import '../css/Home.css';
import Footer from './Footer';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

function SignInModal(props) {

  const [signinModalShow, setSignInModalShow] = React.useState(false);
  const [registerModalShow, setRegisterModalShow] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const numericOdds = (e) => {
    if(e.target.name == "firstname" || e.target.name == "lastname" || e.target.name == "city"){
        var regName = /^[a-zA-Z ]+$/;
        if (!regName.test(e.target.value.trim())) {
            e.target.value="";
            setError('Invalid '+e.target.name+' given.');
        }else{
            setError('');
        }
    }else if(e.target.name == "email"){
        if( !(/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid email given.');
        }else{
          setError('');
        }
    }else if(e.target.name == "phonenumber"){
        if( !(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid phone number given.');
        }else{
            setError('');
        }
    }
    return false;
  }

  const login = (e) => {
      e.preventDefault();
      auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        var user = userCredential.user;
        console.log(user)
          setEmail('');
          setPassword('');
          setError('');

          alert("You have successfully logged in");
        //  props.onHide();

/// get cart data
  try{
      db.collection('cartdata').doc(user.uid).get().then(snapshot => {
      console.log(snapshot.data());
        //try { setCurrentUser(snapshot.data()); }catch(e){ }

        try { localStorage.setItem("shoppingCart",JSON.stringify([...snapshot.data().shoppingCart])); }catch(e){ }
        try { localStorage.setItem("totalQty", snapshot.data().totalQty); }catch(e){ }
        try { localStorage.setItem("totalPrice", snapshot.data().totalPrice); }catch(e){ }

      }); }catch(e){}



      setTimeout(
          function() {
              window.location.href="";
          }
          .bind(this),
          3000
      );

      }).catch(err => setError(err.message));
  }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton className='border-0 position-absolute w-100 p-4' style={{'zIndex':1}}>
        <Modal.Title id="contained-modal-title-vcenter">
        <h3 className='my-0'>Login</h3>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form autoComplete="off" className="registration-form w-100 m-auto" onSubmit={login} >
        {error && <><br /><br /><div className="row m2 p2" style={{'color':'red'}}>{error}</div><br /></>}
        {!error && <><br /><br /></>}

            <label htmlFor="email">Email</label>
            <input type="email" className='form-control' required
                onChange={(e) => setEmail(e.target.value)} value={email} name="email"  onKeyUp={numericOdds} />
            <br />
            <label htmlFor="password">Password</label>
            <input type="password" className='form-control' required
                onChange={(e) => setPassword(e.target.value)} value={password} />
            <br />
            <button type="submit" className='btn btn-success btn-md mybtn'>LOGIN</button>
        </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

export const Cashout = (props) => {
    const [signinModalShow, setSignInModalShow] = React.useState(false);
    const navigate = useNavigate();

    // read data from cart context
    const { shoppingCart, totalPrice, totalQty, dispatch } = useContext(CartContext);

    // defining state

    const [custinfo,setCustInfo] = useState(true);
    const [shipinfo,setShipInfo] = useState('');
    const [paymet,setPayMet] = useState('');
    const [suceOrde,setSuceOrde] = useState('');
    const [fname,setFName] = useState('');
    const [lname,setLName] = useState('');
    const [company,setCompany] = useState('');

    //shipping related
    const [shippingmethod,setShippingMethod] = useState('');

    //Payment related
    const [cardNumber,setCardNumber] = useState('');
    const [cardName,setCardName] = useState('');
    const [cardExp,setCardExp] = useState('');
    const [cardCvv,setCardCvv] = useState('');
    const [billAddFlag,setBillAddFlag] = useState('');

    const [bName,setBName] = useState('');
    const [bName2,setBName2] = useState('');
    const [bCompany,setBCompany] = useState('');
    const [bAddress,setBAddress] = useState('');
    const [bapt,setBapt] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [cell, setCell] = useState('');
    const [address, setAddress] = useState('');
    const [saddress, setsAddress] = useState('');
    const [country,setCountry] = useState();
    const [state,setState] = useState();
    const [zip,setZip] = useState();
    //const [totalPrice, setTotalPrice] = useState('');
    //const [totalQty, setTotalQty] = useState('');
    const [error, setError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const baseURL = "https://vaofd.com/apis/sendmail.php";
    var formData = new FormData();
    const  h = {
                      headers: {
                          'Content-Type': 'application/x-www-form-urlencoded'
                      }
                  };
    useEffect(() => {
        /*auth.onAuthStateChanged(user => {
            if (user) {
                db.collection('SignedUpUsersData').doc(user.uid).onSnapshot(snapshot => {
                    setName(snapshot.data().Name);
                    setEmail(snapshot.data().Email);
                })
            }
            else {
              navigate('/login');
            }
        })*/
    })

    const numericOdds = (e) => {
      if(e.target.name == "fname" || e.target.name == "lname" || e.target.name == "cardName"){
          var regName = /^[a-zA-Z]+$/;
          if (!regName.test(e.target.value)) {
              e.target.value="";
          }
      }else if(e.target.name == "email"){
          if( !(/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(e.target.value)) ){
              //e.target.value="";
          }
      }else if(e.target.name == "phonenumber"){
          if( !(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(e.target.value)) ){
              //e.target.value="";
          }
      }else if(e.target.name == "cardNumber"){

        if(e.target.value.length>16){
          e.target.value = e.target.value.substring(0, 16);
        }

      }else if( e.target.name == "cardExp" ){
        if(e.target.value.length>5){
          e.target.value = e.target.value.substring(0, 5);
        }

        var crdExpReg = /^[0-9]{2}\/[0-9]{2}$/;
        if (!crdExpReg.test(e.target.value)) {
            //e.target.value="";
            setError('Invalid card expiry format given.');
        }else{
          setError('');
        }

      }else if( e.target.name == "cardCvv" ){
        if(e.target.value.length>3){
          e.target.value = e.target.value.substring(0, 3);
        }
        var crdCvvReg = /^[0-9]{3}$/;
        if (!crdCvvReg.test(e.target.value)) {
            //e.target.value="";
            setError('Invalid card cvv format given.');
        }else{
          setError('');
        }

      }
      return false;
      var valid = /^[1-9]{1}[0-9]{0,1}$/
      var number = /^[1-9]{1}$ | ^[1-9]{1}[0-9]{1}$/
      var lastValid = '';
      var n;
      //console.log('Key pressed : ', event.keyCode)
      if (!valid.test(e.target.value)) {
        console.log('target :' + e.target.value);
        n = e.target.value.match(number);
        console.log('n :' + n)
        e.target.value = n ? n : lastValid;
      } else {
        lastValid = e.target.value;
        console.log('lastValid :' + lastValid)
      }
    }

    const custdeftSubmit = (e) => {
        e.preventDefault();
        setCustInfo(true);
        setShipInfo(false);
        setPayMet(false);
        setSuceOrde(false);
    }
    const custInfoSubmit = (e) => {
        e.preventDefault();
        var regName = /^[a-zA-Z]+$/;
        if( !email){
          setError('Email is mandatory');
          return false;
        }else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                setError('Invalid email format.');
                return false;
        }else if( !fname){
          setError('First Name is mandatory');
          return false;
        }else if(!regName.test(fname)){
            setError('Invalid first name given.');
            return false;
        }else if( !lname){
          setError('Last Name is mandatory');
          return false;
        }else if(!regName.test(lname)){
                setError('Invalid last name given.');
                return false;
        }else if( !address){
          setError('Address line is mandatory');
          return false;
        }/*else if( !country){
          setError('Country is mandatory');
          return false;
        }*/else if( !state){
          setError('State is mandatory');
          return false;
        }else if( !zip){
          setError('Zip is mandatory');
          return false;
        }
        setError('');
        setCustInfo(false);
        auth.onAuthStateChanged(user => {
            if (user) {
            }
            else {
        auth.createUserWithEmailAndPassword(email,"Vaofd!@#*").then((cred)=> {
          db.collection('SignedUpUsersData').doc(cred.user.uid).set({
            FirstName:fname,
            MiddleName:"",
            LastName:lname,
            Email:email,
            Password:"Vaofd!@#*",
            PhoneNumber:"",
            Company:company,
            Address1:address,
            Address2:saddress,
            Address3:"",
            City:country,
            State:state,
            Pincode:zip
          }).then(()=>{

          }).catch(err=>setError(""));
        }).catch(err=>setError(""));
      }
  })
        setShipInfo(true);
        setPayMet(false);
        setSuceOrde(false);
    }

    const shipInfoSubmit = (e) => {
        e.preventDefault();
        setCustInfo(false);

        setShipInfo(false);
        setPayMet(true);
        setSuceOrde(false);
    }

    const payMetSubmit = (e) => {
        e.preventDefault();



        setCustInfo(false);
        setShipInfo(false);
        setPayMet(false);
        setSuceOrde(true);
    }

    const suceOrdeSubmit = (e) => {
        e.preventDefault();
        setCustInfo(false);
        setShipInfo(false);
        setPayMet(false);
        setSuceOrde(true);
        cashoutSubmit();
    }

    const goToLogin = (e) => {
        navigate('/login');
    }

    const cashoutSubmit = (e) => {
        e.preventDefault();
var regName = /^[a-zA-Z]+$/;
var re  = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;
        if(!cardNumber){
        	setError("Card Number is mandatory");
        	return false;
        }else if (!/^([0-9]{16})$/.test(cardNumber)){
                setError('Invalid card number format.');
                return false;
        }else if( !cardName){
          setError('Card Name is mandatory');
          return false;
        }else if(!regName.test(cardName)){
            setError('Invalid card name given.');
            return false;
        }else if(!cardCvv){
        	setError("Card cvv is mandatory")
        }else if(!/^([0-9]{3})$/.test(cardCvv)){
        	setError("Invalid card cvv");
        }else if(!cardExp){
        	setError("Card expiry is mandatory");
        	return false;
        }else if(!re.test(cardExp)){
        	setError("Invalid card expiry");
        }
        setError("");
        setCustInfo(false);
        setShipInfo(false);
        setPayMet(false);
        setSuceOrde(true);
        auth.onAuthStateChanged(user => {
            if (user) {
                const date = new Date();
                const time = date.getTime();
                db.collection('Buyer-info' + user.uid).doc('_' + time).set({
                    BuyerName: "bname"+user.FirstName,
                    BuyerEmail: email,
                    BuyerCell: cell,
                    BuyerAddress: address,
                    BuyersAddress: saddress,
                    BuyerPayment: totalPrice+(totalPrice*0.15)+(totalPrice*0.05),
                    BuyerQuantity: totalQty
                }).then(() => {
                  formData.append('ajax_type', 'json');
                  formData.append('action', 'ordersuccess');
                  formData.append('BuyerName', user.FirstName);
                  formData.append('BuyerEmail', email);
                  formData.append('BuyerCell', cell);
                  formData.append('BuyerAddress', address);
                  formData.append('BuyerPayment', totalPrice+(totalPrice*0.15)+(totalPrice*0.05));
                  formData.append('BuyerQuantity', totalQty);
                  axios.post(baseURL, formData,h).then(function(response)
                              {

                  });

                    setCell('');
                    setAddress('');
                    setsAddress('');
                    dispatch({ type: 'EMPTY' });
                    setSuccessMsg('Your order has been placed successfully. Thanks for visiting us. You will be redirected to home page after 5 seconds');
                    setTimeout(() => {
                        navigate('/')
                    }, 5000)
                }).catch(err => setError(err.message))
            }
        })
    }

    return (
        <>

        <div className="App page-wrapper">
          <header className='header'>
            <Navbar user={props.user} />
            </header>
            <main className='main'>
            <section className="pt-10 pb-10">
            <div className="container" >
            <div className="row">
            <div className="col-8">
            {custinfo &&
            <div className='container text-left' ><SignInModal show={signinModalShow} onHide={() => setSignInModalShow(false)}  />
                <br />
                <h2>Checkout - Customer Info</h2>
                {!props.user &&
               <span>Already have an account?<a href="javascripit:void(0)" onClick={() => setSignInModalShow(true)}>Log In</a></span>}
                <br />
                {successMsg && <div className='success-msg'>{successMsg}</div>}
                <form autoComplete="off" className='form-group' onSubmit={custInfoSubmit}>
                  <div className="row">
                      <div className="col-12">
                    <input type="email" className='form-control' required placeholder="Email"
                        value={email} onChange={(e)=>setEmail(e.target.value)}  />
                        </div>
                        </div>
                    <br />
                    <hr />
                    <h2>Shipping Address</h2>
                    <br />
                    <div className="row">
                        <div className="col-6">
                    <input type="text" className='form-control' required placeholder="First Name"
                        value={fname} onChange={(e)=>setFName(e.target.value)} name="fname" onKeyUp={numericOdds}  />
                      </div>
                      <div className="col-6">
                    <input type="text" className='form-control' required placeholder="Last Name"
                        value={lname} onChange={(e)=>setLName(e.target.value)} name="lname" onKeyUp={numericOdds} />
                        </div>
                        </div>
                    <br />
                    <div className="row">
                    <div className="col-12">
                    <input type="text" className='form-control' required
                        onChange={(e) => setCompany(e.target.value)} value={company} placeholder='Comapny' />
                        </div>
                        </div>
                    <br />
                    <div className="row">
                        <div className="col-8">
                    <input type="text" className='form-control' required placeholder="Address"
                        onChange={(e) => setAddress(e.target.value)} value={address} />
                    </div>

                        <div className="col-4">
                    <input type="text" className='form-control' placeholder="Apt. (optional)"
                        onChange={(e) => setsAddress(e.target.value)} value={saddress} />
                        </div>
                        </div>
                    <br />
                    <div className="row">
                        <div className="col-4">
                    <select className='form-control' required placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)} value={country} >
                        <option value="USA">USA </option>
                        </select>
                    </div>

                        <div className="col-4">
                    <select className='form-control' required placeholder="State"
                        onChange={(e) => setState(e.target.value)} value={state} >
                        <option value="">State</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DC">District of Columbia</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New York</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WV">West Virginia</option>
                        <option value="WY">Wyoming</option>
                        </select>
                    </div>

                        <div className="col-4">
                    <input type="text" className='form-control' required placeholder="Zip"
                        onChange={(e) => setZip(e.target.value)} value={zip}   />
                        </div>
                        </div>
                    <br />
                    <hr />
                    <NavLink  to='/cartproducts'>
                    Return to Cart  </NavLink>
                                     <button type="submit" className='btn btn-primary btn-md mybtn'>Continue to Shipping Method</button>
                </form>
                {error && <span className='error-msg'>{error}</span>}
            </div>
          }
          {shipinfo &&
          <div className='container text-left' >
              <br />
              <h2>Shipping Address</h2>
              <span>


                    <span>

                      First Name: {fname}
                      <br />
                      Last Name: {lname}
                      <br />
                      Company: {company}
                      <br />
                      Address: {address}
                      <br />
                      <a href="void(0)" onClick={custdeftSubmit}>Edit</a>
                      </span>
                      <br />
              </span>
              <hr />
              {successMsg && <div className='success-msg'>{successMsg}</div>}
              <form autoComplete="off" className='form-group' onSubmit={shipInfoSubmit}>
                  <h2>Shipping Method</h2>
                  <div className="row">
                      <div className="col-12">
                  <input name="smrb" type="radio" className='' required
                      value="2.20" onChange={(e)=>setShippingMethod(e.target.value)}  />UPS Ground <span>$2.20</span>
                      </div>

                  <br />

                      <div className="col-12">
                  <input name="smrb" type="radio" className='' required
                      value="5.50" onChange={(e)=>setShippingMethod(e.target.value)}  />UPS 3 Day Select <span>$5.50</span>
                      </div>
                  <br />

                      <div className="col-12">
                  <input name="smrb" type="radio" className='' required
                      value="9.50" onChange={(e)=>setShippingMethod(e.target.value)}  />UPS 2nd Day Air <span>$9.50</span>
                      </div>
                      <br />

                          <div className="col-12">
                      <input name="smrb" type="radio" className='' required
                          value="12.50" onChange={(e)=>setShippingMethod(e.target.value)}  />UPS Next Day Air <span>$12.50</span>

                          </div>
                          </div>
                  <br />
                  <hr />
                  <button type="submit" className='btn btn-primary btn-md mybtn'>Continue to Payment Method</button>
              </form>
              {error && <span className='error-msg'>{error}</span>}
          </div>
        }
        {paymet &&
        <div className='container text-left' >
            <br />
            <h2>Shipping Address</h2>
            <span>
            <br />
            First Name: {fname}
            <br />
            Last Name: {lname}
            <br />
            Company: {company}
            <br />
            Address: {address}
            <br /> <a href="void(0)" onClick={custdeftSubmit}>Edit</a>
</span>
            <br />
            <h2>Shipping Method </h2>
            <span> {shippingmethod && (shippingmethod === '2.20' && <>UPS Ground ${shippingmethod}</>) }
            {shippingmethod && (shippingmethod === '5.50' && <>UPS 3 Day Select ${shippingmethod}</>) }
            {shippingmethod && (shippingmethod === '9.50' && <>UPS 2nd Day Air ${shippingmethod}</>) }
            {shippingmethod && (shippingmethod === '12.50' && <>UPS Next Day Air ${shippingmethod}</>) }

              <a href="void(0)" onClick={custInfoSubmit}>Edit</a>
</span>
            <br />
            <h2>Payment Method</h2>
            <br />
            {successMsg && <div className='success-msg'>{successMsg}</div>}
            
            <form autoComplete="off" className='form-group' onSubmit={cashoutSubmit}>
            <div className="row">
            <div className="col-12">
                <input type="number" className='form-control' required
                    value={cardNumber} placeholder="Card number"   onChange={(e)=>setCardNumber(e.target.value)} name="cardNumber" onKeyUp={numericOdds} />
                    </div></div>
                    <br />
                    <div className="row">
                        <div className="col-5">
                <input type="text" className='form-control' required
                    value={cardName} placeholder="Name on card" onChange={(e)=>setCardName(e.target.value)} name="cardName" onKeyUp={numericOdds}  />
            </div>
              <div className="col-5">
                    <input type="text" className='form-control' required
                        value={cardExp} placeholder="MM/YY" onChange={(e)=>setCardExp(e.target.value)} name="cardExp" onKeyUp={numericOdds}  />
                      </div>
                    <div className="col-2">
                        <input type="password" className='form-control' required
                            value={cardCvv} placeholder="CVV" onChange={(e)=>setCardCvv(e.target.value)} name="cardCvv" onKeyUp={numericOdds}  />
                      </div>
                        </div>
                <hr />
                <br />
                <h2>Billing Address</h2>
                <div className="row">
                    <div className="col-12">
                      <input type="radio" required name="billaddr" value="same" onChange={(e)=>setBillAddFlag(e.target.value)} /> Same as shipping address
                    </div>
                    <br />
                    <div className="col-12">
                      <input type="radio" value="diff" required name="billaddr" onChange={(e)=>setBillAddFlag(e.target.value)} /> Use a different billing address
                    </div>
                </div>
                <br />
                { billAddFlag && ( billAddFlag === 'diff' &&
                <>
                <div className="row">
                    <div className="col-6">
                <input type="text" className='form-control' required placeholder="First Name"
                    value={bName} onChange={(e)=>setBName(e.target.value)}  />
                </div>

                    <div className="col-6">

                <input type="text" className='form-control' required placeholder="Last Name"
                    value={bName2} onChange={(e)=>setBName2(e.target.value)}  />
                    </div>
                    </div>
                <br />
                <div className="row">
                    <div className="col-12">
                <input type="text" className='form-control' required placeholder="Company"
                    onChange={(e) => setBCompany(e.target.value)} value={bCompany} placeholder='' />
                    </div>
                    </div>
                <br />
                <div className="row">
                    <div className="col-8">
                <input type="text" className='form-control' required placeholder="Address"
                    onChange={(e) => setBAddress(e.target.value)} value={bAddress} />
              </div>

                  <div className="col-4">

                <input type="text" className='form-control' required placeholder="Apt. (optional)"
                    onChange={(e) => setBapt(e.target.value)} value={bapt} />
                    </div> </div>
                <br />
                <div className="row">
                    <div className="col-4">
                <input type="number" className='form-control' placeholder="bCountry"
                    value=""  />
                </div>

                    <div className="col-4">
                <input type="number" className='form-control' placeholder="bState"
                    value=""  />
                </div>

                    <div className="col-4">
                <input type="number" className='form-control' placeholder="bZip"
                    value=""   />
                    </div></div>
                <br />
            </>  )}
              <br />
            <div className="row">
            <div className="col-12">
              <input type="radio" /> Save my information for a faster checkout
              </div>
            </div>
            <br />
            <hr />
                <button type="submit" className='btn btn-primary btn-md mybtn'>Complete Order</button>
            </form>
            {error && <span className='error-msg'>{error}</span>}
        </div>
      }
      {suceOrde &&
      <div className='container text-left' >
          <br />
          <h3>Order 121312212</h3>
          <h2>Thank you, Vaofd User!</h2>
          <hr />
          <h3> Your order is confirmed.</h3>
          <h3> We've accepted your order and we're getting it ready </h3>
          <br />
          <hr />
          <h2> Customer Information </h2>
          <br />
          <div className="row">
                <div className="col-6 text-left">
                    <h3>Shipping Address</h3>
                    <br />
                    {fname} {lname} {company} {address}
                </div>
                <div className="col-4 text-left">
                <h3>Billing Address</h3>
                <br />
                { billAddFlag && ( billAddFlag === 'same' &&
                <>{fname} {lname} {company} {address}</>
              )}
                { billAddFlag && ( billAddFlag === 'diff' &&
                <>{bName} {bName2} {bCompany} {bAddress}</>
              )}
                </div>
          </div>

          <br />
          <div className="row">
                <div className="col-6 text-left">
                    <h3>Shipping Method</h3>
                    <br />
                    {shippingmethod && (shippingmethod === '2.20' && <>UPS Ground ${shippingmethod}</>) }
                    {shippingmethod && (shippingmethod === '5.50' && <>UPS 3 Day Select ${shippingmethod}</>) }
                    {shippingmethod && (shippingmethod === '9.50' && <>UPS 2nd Day Air ${shippingmethod}</>) }
                    {shippingmethod && (shippingmethod === '12.50' && <>UPS Next Day Air ${shippingmethod}</>) }


                </div>
                <div className="col-4 text-left">
                <h3>Payment Method</h3>
                <br />
                {cardNumber} {cardName} {cardExp} {cardCvv}
                </div>
          </div>

          <hr />

          {error && <span className='error-msg'>{error}</span>}
      </div>
    }
    </div><div className="col-4">
    <h2>Summary ({totalQty} item)</h2>
    <br />
    <div className="row">
          <div className="col-8 text-left">Subtotal</div>
          <div className="col-4 text-right">${totalPrice}.00</div>
    </div>
    {shippingmethod && <>
    <div className="row">
          <div className="col-8 text-left">Shipping (5%)</div>
           <div className="col-4 text-right">${totalPrice*0.05}</div>
    </div>
    </>
    }
    <div className="row">
          <div className="col-8 text-left">Est. Taxes (15%)</div>
          <div className="col-4 text-right">${totalPrice*0.15}</div>
    </div>
    {shippingmethod &&
    <div className="row">
    <div className="col-8 text-left">
    {shippingmethod && (shippingmethod === '2.20' && <>UPS Ground ${shippingmethod}</>) }
    {shippingmethod && (shippingmethod === '5.50' && <>UPS 3 Day Select ${shippingmethod}</>) }
    {shippingmethod && (shippingmethod === '9.50' && <>UPS 2nd Day Air ${shippingmethod}</>) }
    {shippingmethod && (shippingmethod === '12.50' && <>UPS Next Day Air ${shippingmethod}</>) }
    </div>
    <div className="col-4 text-right">${shippingmethod}</div>
    </div>
    }
    <br />
    <hr />
    <div className="row">
          <div className="col-12 text-left">Gift card or discount code</div>
    </div>
    <div className="row">
          <div className="col-8 text-left">
            <input type="text" />
          </div>
          <div className="col-4 text-right">
            <button>Apply</button>
          </div>
    </div>
    <hr />
    <div className="row">
          <div className="col-8 text-left">Total</div>
          <div className="col-4 text-right">${parseFloat(totalPrice) + parseFloat(shippingmethod?shippingmethod:0) + parseFloat(totalPrice*0.15) + parseFloat(shippingmethod)}</div>
    </div>
    </div></div></div>
            </section>
            </main>
            <footer className="footer appear-animate">
          <Footer />
        </footer>
        </div>
        </>
    )
}
