import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

//aimport "./styles.css";

// import required modules
import { Pagination } from "swiper";
import millets from '../assets/images/demos/demo1/categories/millets.jpg';
import jowar from '../assets/images/demos/demo1/categories/jowar.jpg';
import dried_millet from '../assets/images/demos/demo1/categories/dried-millet.jpg';
import millet_flour from '../assets/images/demos/demo1/categories/millet_flour.jpg';
import hulled_millet from '../assets/images/demos/demo1/categories/hulled-millet.jpg';
import spray from '../assets/images/demos/demo1/categories/spray.jpg';
import red from '../assets/images/demos/demo1/categories/red.jpg';
import organic from '../assets/images/demos/demo1/categories/organic.jpg';
import pearl from '../assets/images/demos/demo1/categories/pearl.jpg';
import foxtail from '../assets/images/demos/demo1/categories/foxtail.jpg';

export default function App() {
  //swiper settings
  const
      photos=[
        {name:"millets",img:millets},
        {name:"jowar",img:jowar},
        {name:"dried millet",img:dried_millet},
        {name:"millet flour",img:millet_flour},
        {name:"hulled millet",img:hulled_millet},
        {name:"spray",img:spray},
        {name:"red",img:red},
        {name:"organic",img:organic},
        {name:"pearl",img:pearl},
        {name:"foxtail",img:foxtail},
      ];


  return (
    <>
    <section className="category-section top-category bg-grey pt-10 pb-10 appear-animate" >
    <div className="container pb-2">
    <h2 className="title justify-content-center pt-1 ls-normal mb-5">Top Categories Of The Month</h2>
    <div className="swiper">
      <Swiper
        slidesPerView={5}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
      { photos.map((photo,key)=>{
        return (
        <SwiperSlide key={key}>

        <div
              className="swiper-slide category category-classic category-absolute overlay-zoom br-xs">
              <a href="shop-banner-sidebar.html" className="category-media">
                  <img src={photo.img} alt="Category"
                      width="130" height="130" />
              </a>
              <div className="category-content">
                  <h4 className="category-name">{photo.name}</h4>
                  <a href="shop-banner-sidebar.html"
                      className="btn btn-primary btn-link btn-underline">Shop
                      Now</a>
              </div>
          </div>
        </SwiperSlide>
      )
        }) }

      </Swiper>
      </div>
      </div>
        </section>
    </>
  );
}
