import React, { createContext, useReducer } from 'react'
import { CartReducer } from './CartReducer'

export const CartContext = createContext();

export const CartContextProvider = (props) => {

    const [cart, dispatch] = useReducer(CartReducer,
        { shoppingCart: localStorage.getItem("shoppingCart")?JSON.parse(localStorage.getItem("shoppingCart")):[],
        totalPrice: parseInt((localStorage.getItem("totalPrice")?localStorage.getItem("totalPrice"):0)),
        totalQty: parseInt((localStorage.getItem("totalQty")?localStorage.getItem("totalQty"):0))
      })

    return (
        <CartContext.Provider value={{ ...cart, dispatch }}>
            {props.children}
        </CartContext.Provider>
    )
}
