import React, { useContext,useState } from 'react'
import { NavLink } from "react-router-dom";
import { ProductsContext } from '../global/ProductsContext'
import { CartContext } from '../global/CartContext'
//import Sidepanal from './Sidepanal';


export const Products = () => {

    const { products } = useContext(ProductsContext);
    const { dispatch } = useContext(CartContext);





  //  const [filterProducts, setfilterProducts] = useState(products);

    /*setTimeout(() => {
            if(!filterProducts){
              setfilterProducts(products);
            }
        }
    , 3000);
    const onFilterChangeHandler = (e) =>{

        if(e.target.id !== 'all'){
            let filteritems = products.filter(i=>{
                return i.ProductName === e.target.id
            })
            console.log('filteritems: ',filteritems)
            if(filteritems.length === 0){
                alert('No Products to display');
            }else{
                setfilterProducts(filteritems)
            }
        }else{
            setfilterProducts(products)
        }
    }*/
    return (
        <>

       {/*} {products.length === 0 && <div>slow internet (OR) no products to display</div>} */}
          {products.length !== 0 &&
            products.map(product => (
                  <div className="product-wrap" style={{border: '1px solid rgba(0, 0, 0, 0.20)'}}  key={product.ProductName}>

              <div className="product text-center" >
                      <figure className="product-media">
                            <NavLink  to={`/productdetail/${product.ProductName}`}>
                                  <img src={product.ProductImg} alt="Product" width="300" height="338" />
                                  <img src={product.ProductImg} alt="Product" width="300" height="338" />
                            </NavLink >
                         {/* <div className="product-action-vertical">
                                <a href="#" className="btn-product-icon btn-cart w-icon-cart" title="Add to cart"></a>
                                  <a href="#" className="btn-product-icon btn-wishlist w-icon-heart" title="Add to wishlist"></a>
                                  <a href="#" className="btn-product-icon btn-quickview w-icon-search" title="Quickview"></a>
                                  <a href="#" className="btn-product-icon btn-compare w-icon-compare" title="Add to Compare"></a>
            </div> */}
                                                  </figure>
                      <div className="product-details">
                              <h4 className="product-name"><NavLink  to={`/productdetail/${product.ProductName}`}>{product.ProductName}</NavLink></h4>
                           {/*   <div className="ratings-container">

                                      <NavLink  to={`/productdetail/${product.ProductName}`} className="rating-reviews">(1 Reviews)</NavLink>
                              </div>*/}
                              <div className="product-price">
                                  <ins className="new-price">&#36; {product.ProductPrice}.00</ins>
                              </div>
                              <button className='addcart-btn' onClick={() => dispatch({ type: 'ADD_TO_CART', id: product.ProductID, product })}>ADD TO CART</button>
                      </div>
              </div>
              </div>
              ))}

              {/*<Sidepanal onFilterChange = {(e)=> onFilterChangeHandler(e)} />*/}
        </>
    )
}
