
import pay from '../assets/images/payment.png';

function Footer() {
    return (
    <>
    <div className="container">
    <div className="footer-bottom">
            <div className="footer-left">
                <p className="copyright">Copyright © 2022 VAOFD. All Rights Reserved.</p>
            </div>
            <div className="footer-right">
                <span className="payment-label mr-lg-8">We're using safe payment for</span>
                <figure className="payment">
                    <img src={pay} alt="payment" width="159" height="25" />
                </figure>
            </div>
        </div>
    </div>
    </>
    )
}

export default Footer;
