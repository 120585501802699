import React, {useState, useEffect, useContext} from 'react';
import {stlist} from './register_con';
//import useForm from './useForm';
//import { CartContext } from '../global/CartContext';
import {auth, db} from '../config/Config';
import {Link,useNavigate} from 'react-router-dom';
import axios from "axios";

import logo from '../images/logo-VOAFD.jpg';
import engflag from '../images/flags/eng.png';
import fraflag from '../images/flags/fra.png';


import { Button, FloatingLabel,Form } from "react-bootstrap";
import '../App.css';

import { Icon } from 'react-icons-kit'
import { cart } from 'react-icons-kit/entypo/cart'
import Modal from 'react-bootstrap/Modal';
import { CartContext } from '../global/CartContext'

function MyPrModal(props) {
  // profile popup
  const [currentUser, setCurrentUser] = useState();
  const [mfirstname, setmFirstName] = useState();
  const [mlastname, setmLastName] = useState('');
  const [memail, setmEmail] = useState('');
  const [mpassword, setmPassword] = useState('');
  const [mphonenumber, setmPhoneNumber] = useState('');
  const [mcompany, setmCompany] = useState('');
  const [maddress1, setmAddress1] = useState('');
  const [maddress2, setmAddress2] = useState('');
  const [mcity, setmCity] = useState('');
  const [mstate, setmState] = useState('');
  const [mpincode, setmPincode] = useState('');
  const [mcountry, setmCountry] = useState('');

  const mSignup = (e) =>{

    //signup firebase api
    e.preventDefault();


        var regName = /^[a-zA-Z ]+$/;
        if( !mfirstname){
          setError('First Name is mandatory');
          return false;
        }else if(!regName.test(mfirstname)){
            setError('Invalid first name given, Please enter characters.');
            return false;
        }else if( !mlastname){
          setError('Last Name is mandatory');
          return false;
        }else if(!regName.test(mlastname)){
                setError('Invalid last name given, Please enter characters.');
                return false;
        }else if( !mphonenumber){
          setError('Phonenumber is mandatory');
          return false;
        }else if( mphonenumber.length !=10 || mphonenumber<1 ){
          setError('Invalid phone number given, Please enter 10 digit number');
          return false;
        }else if( !maddress1){
          setError('Address line is mandatory');
          return false;
        }else if( !mcity){
          setError('City is mandatory');
          return false;
        }else if(!regName.test(mcity)){
                setError('Invalid city given, Please enter characters.');
                return false;
        }else if( !mstate){
          setError('State is mandatory');
          return false;
        }else if( !mpincode){
          setError('Zip is mandatory');
          return false;
        }else if( mpincode.length !=5 || mpincode<1 ){
          setError('Invalid zip number given, Please enter 5 digit number');
          return false;
        }else if( !mcountry){
          setError('Country is mandatory');
          return false;
        }

    auth.onAuthStateChanged(user => {
        if (user) {

      db.collection('SignedUpUsersData').doc(user.uid).set({
        FirstName:mfirstname,
        LastName:mlastname,

        PhoneNumber:mphonenumber,
        Company:mcompany,
        Address1:maddress1,
        Address2:maddress2,
        City:mcity,
        State:mstate,
        Pincode:mpincode,
        Country:mcountry
      }).then(()=>{

        setError('');
        alert("You are successfully updated");
        //React.setSignInModalShow(false);
        //navigate('/login');
        //auth.signOut().then(function() {
        // Sign-out successful.
        //}).catch(function(error) {
        // An error happened.
      //  });
         props.onHide();
      }).catch(err=>setError(err.message));
    }
  });
  }

  useEffect(() => { auth.onAuthStateChanged(user => {
      if (user) {
          db.collection('SignedUpUsersData').doc(user.uid).get().then(snapshot => {
            try{ setCurrentUser(snapshot.data()); }catch(e){ }
            try{ setmFirstName(snapshot.data().FirstName); }catch(e){ }
            try{ setmLastName(snapshot.data().LastName); }catch(e){ }
            try{ setmEmail(snapshot.data().Email); }catch(e){ }
            try{ setmPassword(snapshot.data().Password); }catch(e){ }
            try{ setmPhoneNumber(snapshot.data().PhoneNumber); }catch(e){ }
            try{ setmCompany(snapshot.data().Company); }catch(e){ }
            try{ setmAddress1(snapshot.data().Address1); }catch(e){ }
            try{ setmAddress2(snapshot.data().Address2); }catch(e){ }
            try{ setmCity(snapshot.data().City); }catch(e){ }
            try{ setmState(snapshot.data().State); }catch(e){ }
            try{ setmPincode(snapshot.data().Pincode); }catch(e){ }
            try{ setmCountry(snapshot.data().Country); }catch(e){ }
          })
      }
      else {

      }
  });
}, []);


const [error,setError] = useState('');

  const Signup = () => {

  }
      return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Header closeButton className='p-4' style={{'zIndex':1}}>
            <Modal.Title id="contained-modal-title-vcenter">
            <h3 className='my-0'>My Profile</h3>
            </Modal.Title>

          </Modal.Header>
          <Modal.Body>
          <Form id="registrationform" noValidate onSubmit={mSignup} className="registration-form w-100 m-auto">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 py-3" style={{'height':'500px','overflowX':'hidden','overflowY':'auto'}}>
              {error && <><div className="row m2 p2" style={{'color':'red'}}>{error}</div><br /></>}
              <br />
                      <FloatingLabel
                              controlId="regFirstName"
                              label="First Name*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" readonly="readonly" disabled="true" placeholder="First Name" defaultValue={mfirstname} onChange={(e)=>setmFirstName(e.target.value)} value={mfirstname} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regLastName"
                              label="Last Name*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" readonly="readonly" disabled="true" placeholder="Last Name" value={mlastname} onChange={(e)=>setmLastName(e.target.value)} value={mlastname} />
                      </FloatingLabel>





                      <FloatingLabel
                              controlId="regPhoneNumber"
                              label="Phone Number*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="tel" readonly="readonly" disabled="true" placeholder="+__-____ ____" value={mphonenumber} onChange={(e)=>setmPhoneNumber(e.target.value)} value={mphonenumber} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regCompanyName"
                              label="Company Name"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" readonly="readonly" disabled="true" defaultValue={mcompany} placeholder="Company Name" onChange={(e)=>setmCompany(e.target.value)} value={mcompany} />
                      </FloatingLabel>

                      <Form.Label className="fw-bold text-start d-block text-secondary">Address: </Form.Label>
                      <FloatingLabel
                              controlId="regLine1"
                              label=""
                              className="mb-3 col"
                              >

                          </FloatingLabel>
                      <FloatingLabel
                              controlId="regLine1"
                              label="Line 1*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" readonly="readonly" disabled="true" placeholder="Line 1" value={maddress1} onChange={(e)=>setmAddress1(e.target.value)} value={maddress1} />
                      </FloatingLabel>
                      <FloatingLabel
                              controlId="regLine2"
                              label="Line 2"
                              className="mb-3 col"
                              >
                          <Form.Control  type="text" readonly="readonly" disabled="true" placeholder="Line 2" value={maddress2} onChange={(e)=>setmAddress2(e.target.value)} value={maddress2} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regCity"
                              label="City*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text"  readonly="readonly" placeholder="City" disabled="true" onChange={(e)=>setmCity(e.target.value)} value={mcity} />
                      </FloatingLabel>

                      <div className="mb-3 col form-floating"><select  readonly="readonly" disabled="true" className="form-control" required placeholder="Country"
                          onChange={(e) => setmCountry(e.target.value)} value={mcountry} >
                          <option value="USA">USA </option>
                          </select>
                          </div>
                      <div className="mb-3 col form-floating">
                      <select className="form-control" required placeholder="State"  disabled="true"
                          onChange={(e)=>setmState(e.target.value)} value={mstate} >
                          <option value="">Select State</option>
                          { stlist.map((st) =>
                          <option value={st.id}>{st.title}</option>
                        )}

                          </select></div>
                      <FloatingLabel
                              controlId="regPinCode"
                              label="Pin or Zip Code*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" readonly="readonly" disabled="true" placeholder="Pin or Zip Code" onChange={(e)=>setmPincode(e.target.value)} value={mpincode} />
                      </FloatingLabel>

              </div>
                      {/*<div className="wrapper pe-0">
                          <button type="submit" className="w-auto btn btn-primary mx-auto d-block" >Update Details</button>
                      </div>*/}
              {/* <div className="wrapper pe-0">
                  <Button type="submit" className="w-auto btn btn-primary mx-auto d-block">Submit form</Button>
              </div>*/}
          </Form>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );

  }

function MyAccModal(props) {
  const [currentUser, setCurrentUser] = useState();
  const [mfirstname, setmFirstName] = useState();
  const [mlastname, setmLastName] = useState('');
  const [memail, setmEmail] = useState('');
  const [mpassword, setmPassword] = useState('');
  const [mphonenumber, setmPhoneNumber] = useState('');
  const [mcompany, setmCompany] = useState('');
  const [maddress1, setmAddress1] = useState('');
  const [maddress2, setmAddress2] = useState('');
  const [mcity, setmCity] = useState('');
  const [mstate, setmState] = useState('');
  const [mpincode, setmPincode] = useState('');
  const [mcountry, setmCountry] = useState('');

  const numericOdds = (e) => {
    if(e.target.name == "firstname" || e.target.name == "lastname" || e.target.name == "city"){
        var regName = /^[a-zA-Z ]+$/;
        if (!regName.test(e.target.value.trim())) {
            e.target.value="";
            setError('Invalid '+e.target.name+' given, Please enter characters');
        }else{
            setError('');
        }
    }else if(e.target.name == "email"){
        if( !(/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid email given.');
        }else{
          setError('');
        }
    }else if(e.target.name == "phonenumber"){
      if(e.target.value.length>10){
        e.target.value = e.target.value.substring(0, 10);
      }
        if( !(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid phone number given, Please enter 10 digit number');
        }else{
            setError('');
        }
    }else if(e.target.name == "zip"){
      if(e.target.value.length !=5){
        e.target.value = e.target.value.substring(0, 5);
        setError('Invalid zip given, Please enter 5 digit number');
      }else{
        setError('');
      }

    }else if(e.target.name == "password"){
      var lower_pass_err = 0;
      var upper_pass_err = 0;
      var num_pass_err = 0;

var ptext = e.target.value;
      //lower case checking
      if( /\d/.test(ptext) ){
        num_pass_err = 1;
        //setError('Password must contain at least one number and one uppercase and lowercase letter, and characters');
      }

      if( ptext.toLowerCase() !== e.target.value ){
          lower_pass_err = 1;
      }

      if( ptext.toUpperCase() !== e.target.value ){
          upper_pass_err = 1;
      }

      /*if( /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(e.target.value) ){
        spc_pass_err = 0
      }*/
      //console.log("l"+lower_pass_err+" u"+upper_pass_err+" n"+num_pass_err);
      if( lower_pass_err && upper_pass_err && num_pass_err){
        setError('');
      }else{
        setError('Password must contain at least one number and one uppercase and lowercase letter, and characters');

      }

    }
    return false;
  }

  const mSignup = (e) =>{
    e.preventDefault();

    var regName = /^[a-zA-Z ]+$/;
    if( !mfirstname){
      setError('First Name is mandatory');
      return false;
    }else if(!regName.test(mfirstname)){
        setError('Invalid first name given, Please enter characters.');
        return false;
    }else if( !mlastname){
      setError('Last Name is mandatory');
      return false;
    }else if(!regName.test(mlastname)){
            setError('Invalid last name given, Please enter characters.');
            return false;
    }else if( !mphonenumber){
      setError('Phonenumber is mandatory');
      return false;
    }else if( mphonenumber.length !=10 || mphonenumber <1 ){
      setError('Invalid phone number given, Please enter 10 digit number');
      return false;
    }else if( !maddress1){
      setError('Address line is mandatory');
      return false;
    }else if( !mcity){
      setError('City is mandatory');
      return false;
    }else if(!regName.test(mcity)){
            setError('Invalid city given, Please enter characters.');
            return false;
    }else if( !mstate){
      setError('State is mandatory');
      return false;
    }else if( !mpincode){
      setError('Zip is mandatory');
      return false;
    }else if( mpincode.length !=5 || mpincode<1 ){
      setError('Invalid zip number given, Please enter 5 digit number');
      return false;
    }else if( !mcountry){
      setError('Country is mandatory');
      return false;
    }
setError('');
    auth.onAuthStateChanged(user => {
        if (user) {

          user.updatePassword(mpassword).then(() => {
            //alert("ps update Update successful");
          }).catch((error) => {
            //alert("errror" + error);
          });


      db.collection('SignedUpUsersData').doc(user.uid).set({
        FirstName:mfirstname,
        LastName:mlastname,
        Email:memail,
        Password:mpassword,
        PhoneNumber:mphonenumber,
        Company:mcompany,
        Address1:maddress1,
        Address2:maddress2,
        City:mcity,
        State:mstate,
        Pincode:mpincode,
        Country:mcountry
      }).then(()=>{

        setError('');
        alert("my account: You are successfully updated");
        //React.setSignInModalShow(false);
        //navigate('/login');
        //auth.signOut().then(function() {
        // Sign-out successful.
        //}).catch(function(error) {
        // An error happened.
      //  });
         props.onHide();
      }).catch(err=>setError(err.message));
    }
  });
  }

  useEffect(() => { auth.onAuthStateChanged(user => {
      if (user) {
          db.collection('SignedUpUsersData').doc(user.uid).get().then(snapshot => {
            try{ setCurrentUser(snapshot.data());  }catch(e){ }
              try{ setmFirstName(snapshot.data().FirstName);  }catch(e){ }
              try{ setmLastName(snapshot.data().LastName);  }catch(e){ }
              try{ setmEmail(snapshot.data().Email);  }catch(e){ }
            try{   setmPassword(snapshot.data().Password);  }catch(e){ }
            try{   setmPhoneNumber(snapshot.data().PhoneNumber);  }catch(e){ }
            try{   setmCompany(snapshot.data().Company);  }catch(e){ }
            try{   setmAddress1(snapshot.data().Address1);  }catch(e){ }
            try{   setmAddress2(snapshot.data().Address2);  }catch(e){ }
            try{   setmCity(snapshot.data().City);  }catch(e){ }
            try{   setmState(snapshot.data().State);  }catch(e){ }
            try{   setmPincode(snapshot.data().Pincode);  }catch(e){ }
            try{   setmCountry(snapshot.data().Country);  }catch(e){ }
          })
      }
      else {

      }
  });
}, []);


const [error,setError] = useState('');

  const Signup = () => {

  }
      return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Header closeButton className='p-4' style={{'zIndex':1}}>
            <Modal.Title id="contained-modal-title-vcenter">
            <h3 className='my-0'>My Account</h3>
            </Modal.Title>

          </Modal.Header>
          <Modal.Body>
          <Form id="registrationform" noValidate onSubmit={mSignup} className="registration-form w-100 m-auto">
          {error && <><div className="row m2 p2" style={{'color':'red'}}>{error}</div><div className="row m2 p2"></div><br /></>}
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 py-3" style={{'height':'500px','overflowX':'hidden','overflowY':'auto'}}>

              <br />
                      <FloatingLabel
                              controlId="regFirstName"
                              label="First Name*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" placeholder="First Name" defaultValue={mfirstname} onChange={(e)=>setmFirstName(e.target.value)} value={mfirstname} name="firstname" onKeyUp={numericOdds} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regLastName"
                              label="Last Name*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" placeholder="Last Name" value={mlastname} onChange={(e)=>setmLastName(e.target.value)} value={mlastname} name="lastname" onKeyUp={numericOdds} />
                      </FloatingLabel>




                      <FloatingLabel
                              controlId="regPhoneNumber"
                              label="Phone Number*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="number" placeholder="+__-____ ____" value={mphonenumber} onChange={(e)=>setmPhoneNumber(e.target.value)} value={mphonenumber}  name="phonenumber" onKeyUp={numericOdds} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regCompanyName"
                              label="Company Name"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" defaultValue={mcompany} placeholder="Company Name" onChange={(e)=>setmCompany(e.target.value)} value={mcompany} />
                      </FloatingLabel>

                      <Form.Label className="fw-bold text-start d-block text-secondary">Address: </Form.Label>
                      <FloatingLabel
                              controlId="regLine1"
                              label=""
                              className="mb-3 col"
                              >
                      </FloatingLabel>
                      <FloatingLabel
                              controlId="regLine1"
                              label="Line 1*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" placeholder="Line 1" value={maddress1} onChange={(e)=>setmAddress1(e.target.value)} value={maddress1} />
                      </FloatingLabel>
                      <FloatingLabel
                              controlId="regLine2"
                              label="Line 2"
                              className="mb-3 col"
                              >
                          <Form.Control  type="text" placeholder="Line 2" value={maddress2} onChange={(e)=>setmAddress2(e.target.value)} value={maddress2} />
                      </FloatingLabel>

                      <FloatingLabel
                              controlId="regCity"
                              label="City*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="text" placeholder="City" onChange={(e)=>setmCity(e.target.value)} value={mcity}  name="city" onKeyUp={numericOdds} />
                      </FloatingLabel>
                      <div className="mb-3 col form-floating"><select className="form-control" required placeholder="Country"
                          onChange={(e) => setmCountry(e.target.value)} value={mcountry} >
                          <option value="USA">USA </option>
                          </select>
                          </div>
                      <div className="mb-3 col form-floating">
                      <select className="form-control" required placeholder="State"
                          onChange={(e)=>setmState(e.target.value)} value={mstate} >
                          <option value="">Select State</option>
                          { stlist.map((st) =>
                          <option value={st.id}>{st.title}</option>
                        )}

                          </select></div>
                      <FloatingLabel
                              controlId="regPinCode"
                              label="Pin or Zip Code*"
                              className="mb-3 col"
                              >
                          <Form.Control required type="number" placeholder="Pin or Zip Code" onChange={(e)=>setmPincode(e.target.value)} value={mpincode}  name="zip" onKeyUp={numericOdds} />
                      </FloatingLabel>

              </div>
                      {<div className="wrapper pe-0">
                          <button type="submit" className="w-auto btn btn-primary mx-auto d-block" >Update Details</button>
                      </div>}
              {/* <div className="wrapper pe-0">
                  <Button type="submit" className="w-auto btn btn-primary mx-auto d-block">Submit form</Button>
              </div>*/}
          </Form>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );

  }

function SignInModal(props) {

  const [signinModalShow, setSignInModalShow] = React.useState(false);
  const [registerModalShow, setRegisterModalShow] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  //Custom hook call
  //const {numericOdds, values,error } = useForm();
  var errdata = [];
  const numericOdds = (e) => {
    if(e.target.name == "firstname" || e.target.name == "lastname" || e.target.name == "city"){
        var regName = /^[a-zA-Z ]+$/;
        if (!regName.test(e.target.value.trim())) {
            e.target.value="";
            setError('Invalid '+e.target.name+' given.');
            errdata[e.target.name] = 'Invalid '+e.target.name+' given.';
        }else{
            setError('');
            errdata[e.target.name] = '';
        }
    }else if(e.target.name == "email"){
        if( !(/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid email given.');
            errdata[e.target.name] = 'Invalid '+e.target.name+' given.';
        }else{
          setError('');
          errdata[e.target.name] = '';
        }
    }else if(e.target.name == "phonenumber"){
        if( !(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(e.target.value)) ){
            //e.target.value="";
            setError('Invalid phone number given.');
            errdata[e.target.name] = 'Invalid '+e.target.name+' given.';
        }else{
            setError('');
            errdata[e.target.name] = '';
        }
    }
    return false;
  }

  const login = (e) => {
      e.preventDefault();
      auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        var user = userCredential.user;
        console.log(user)
          setEmail('');
          setPassword('');
          setError('');

          alert("You have successfully logged in");
        //  props.onHide();

/// get cart data
  try{
      db.collection('cartdata').doc(user.uid).get().then(snapshot => {
      console.log(snapshot.data());
        //try { setCurrentUser(snapshot.data()); }catch(e){ }
        if(localStorage.totalPrice){

        }else{
        try { localStorage.setItem("shoppingCart",JSON.stringify([...snapshot.data().shoppingCart])); }catch(e){ }
        try { localStorage.setItem("totalQty", snapshot.data().totalQty); }catch(e){ }
        try { localStorage.setItem("totalPrice", snapshot.data().totalPrice); }catch(e){ }
      }
      }); }catch(e){}



      setTimeout(
          function() {
              window.location.href="";
          }
          .bind(this),
          3000
      );

      }).catch(err => setError(err.message));
  }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton className='border-0 position-absolute w-100 p-4' style={{'zIndex':1}}>
        <Modal.Title id="contained-modal-title-vcenter">
        <h3 className='my-0'>Login</h3>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form autoComplete="off" className="registration-form w-100 m-auto" onSubmit={login} >
        {error && <><br /><br /><div className="row m2 p2" style={{'color':'red'}}>{error}</div><br /></>}
        {!error && <><br /><br /></>}

            <label htmlFor="email">Email</label>
            <input type="email" className='form-control' required
                onChange={(e) => setEmail(e.target.value)} value={email} name="email"  onKeyUp={numericOdds} />
            <br />
            <label htmlFor="password">Password</label>
            <input type="password" className='form-control' required
                onChange={(e) => setPassword(e.target.value)} value={password} />
            <br />
            <button type="submit" className='btn btn-success btn-md mybtn'>LOGIN</button>
        </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

function RegisterModal(props) {
  const [firstname, setFirstName] = useState('');
  const [isfname,setIsfname] = useState('');
  const [lastname, setLastName] = useState('');
  const [islname,setIslname] = useState('');
  const [email, setEmail] = useState('');
  const [isemail,setIsemail] = useState('');
  const [password, setPassword] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [isphonenumber,setIsphonenumber] = useState('');
  const [company, setCompany] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [iscity,setIscity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [ispincode,setIspincode] = useState('');
  const [country, setCountry] = useState('USA');
  const [error,setError] = useState('');
  const navigate = useNavigate();
  const [defaultDisabled,setDefaultDisabled] = React.useState("grayc");
  const [registerModalShow, setRegisterModalShow] = React.useState(false);
//const {numericOdds, values,error } = useForm();

var errdata = [];
//const {numericOdds, values,error } = useForm();
  const numericOdds = (e) => {
    var field_name = e.target.name;
    if(e.target.name == "firstname" || e.target.name == "lastname" || e.target.name == "city"){
        var regName = /^[a-zA-Z ]+$/;
        if (!regName.test(e.target.value.trim())) {
            e.target.value="";
            //setError('Invalid '+e.target.name+' given, Please enter characters');
            if(e.target.name == "firstname"){
                errdata.firstname = 'Invalid '+e.target.name+' given, Please enter characters';
                setIsfname('Invalid '+e.target.name+' given, Please enter characters');
            }
            if(e.target.name == "lastname"){
                errdata.lastname = 'Invalid '+e.target.name+' given, Please enter characters';
                setIslname('Invalid '+e.target.name+' given, Please enter characters');
            }
            if(e.target.name == "city"){
                errdata.city = 'Invalid '+e.target.name+' given, Please enter characters';
                setIscity('Invalid '+e.target.name+' given, Please enter characters');
            }

        }else{
            //setError('');
            if(e.target.name == "firstname"){
                //errdata.firstname = '';
                setIsfname('');
            }
            if(e.target.name == "lastname"){
                ///errdata.lastname = '';
                setIslname('');
            }
            if(e.target.name == "city"){
                ///errdata.city = '';
                setIscity('');
            }
        }
    }else if(e.target.name == "email"){
        if( !(/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(e.target.value)) ){
            //e.target.value="";
            //setError('Invalid email given.');
            errdata.field_name = 'Invalid '+e.target.name+' given.';
            setIsemail('Invalid email given.');
        }else{
          //setError('');
          errdata.field_name = '';
          setIsemail('');
        }
    }else if(e.target.name == "phonenumber"){
      if(e.target.value.length>10){
        e.target.value = e.target.value.substring(0, 10);
      }
        if( !(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(e.target.value)) ){
            //e.target.value="";
            //setError('Invalid phone number given, Please enter 10 digit number');
            errdata.field_name = 'Invalid phone number given, Please enter 10 digit number';
            setIsphonenumber('Invalid phone number given, Please enter 10 digit number');
        }else{
            //setError('');
            errdata.field_name = '';
            setIsphonenumber('');
        }
    }else if(e.target.name == "zip"){
      if(e.target.value.length !=5){
        e.target.value = e.target.value.substring(0, 5);
        //setError('Invalid zip given, Please enter 5 digit number');
        errdata.field_name = 'Invalid zip given, Please enter 5 digit number';
        setIspincode('Invalid zip given, Please enter 5 digit number');
      }else{
        //setError('');
        errdata.field_name = '';
        setIspincode('');
      }

    }else if(e.target.name == "password"){
      var lower_pass_err = 0;
      var upper_pass_err = 0;
      var num_pass_err = 0;
      var spc_pass_err = 0;

var ptext = e.target.value;
      //lower case checking
      if( /\d/.test(ptext) ){
        num_pass_err = 1;
        //setError('Password must contain at least one number and one uppercase and lowercase letter, and characters');
      }

      if( ptext.toLowerCase() !== e.target.value ){
          lower_pass_err = 1;
      }

      if( ptext.toUpperCase() !== e.target.value ){
          upper_pass_err = 1;
      }

      if( /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(e.target.value) ){
        spc_pass_err = 1
      }
      //console.log("l"+lower_pass_err+" u"+upper_pass_err+" n"+num_pass_err);
      if( lower_pass_err && upper_pass_err && num_pass_err && spc_pass_err){
        setError('');
        errdata[e.target.name] = '';
      }else{
        setError('Password must contain at least one number and one uppercase and lowercase letter, and characters');
        errdata.field_name = 'Password must contain at least one number and one uppercase and lowercase letter, and characters';

      }

    }
    //this.Signup();
    //console.log(errdata);
    return errdata;
  }

  const  h = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
    const baseURL = "https://vaofd.com/apis/sendmail.php";
    var formData = new FormData();
  const Signup = (e) =>{
    e.preventDefault();

/*password not empty*/
var pass_sub_err = "";
if(password!=""){
      var lower_pass_err = 0;
      var upper_pass_err = 0;
      var num_pass_err = 0;
      var spc_pass_err = 0;

      var ptext = password;
      //lower case checking
      if( /\d/.test(ptext) ){
        num_pass_err = 1;
        //setError('Password must contain at least one number and one uppercase and lowercase letter, and characters');
      }

      if( ptext.toLowerCase() !== password ){
          lower_pass_err = 1;
      }

      if( ptext.toUpperCase() !== password ){
          upper_pass_err = 1;
      }

      if( /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password) ){
        spc_pass_err = 1
      }
      //console.log("l"+lower_pass_err+" u"+upper_pass_err+" n"+num_pass_err);

      if( lower_pass_err && upper_pass_err && num_pass_err && spc_pass_err){
        var pass_sub_err = "";
      }else{
        var pass_sub_err = 'Password must contain at least one number and one uppercase and lowercase letter, and characters';
      }
}
    var regName = /^[a-zA-Z ]+$/;
    if( !firstname){
      setError('First Name is mandatory');

      return false;
    }else if(!regName.test(firstname)){
        setError('Invalid first name given, Please enter characters.');
        return false;
    }else if( !lastname){
      setError('Last Name is mandatory');
      return false;
    }else if(!regName.test(lastname)){
            setError('Invalid last name given, Please enter characters.');
            return false;
    }else if( !email){
      setError('Email is mandatory');
      return false;
    }else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            setError('Invalid email format.');
            return false;
    }else if( !password){
      setError('Password is mandatory');
      return false;
    }else if(pass_sub_err){
      setError(pass_sub_err);
      return false;
    }else if( !phonenumber){
      setError('Phonenumber is mandatory');
      return false;
    }else if( phonenumber.length !=10 || phonenumber<1 ){
      setError('Invalid phone number given, Please enter 10 digit number');
      return false;
    }else if( !address1){
      setError('Address line is mandatory');
      return false;
    }else if( !city){
      setError('City is mandatory');
      return false;
    }else if(!regName.test(city)){
            setError('Invalid city given, Please enter characters.');
            return false;
    }else if( !state){
      setError('State is mandatory');
      return false;
    }else if( !pincode){
      setError('Zip is mandatory');
      return false;
    }else if( pincode.length !=5 || pincode<1 ){
      setError('Invalid zip number given, Please enter 5 digit number');
      return false;
    }else if( !country){
      setError('Country is mandatory');
      return false;
    }
    setError('');
    setDefaultDisabled('');
    //console.log("formsubmitted");
    auth.createUserWithEmailAndPassword(email,password).then((cred)=> {
      db.collection('SignedUpUsersData').doc(cred.user.uid).set({
        FirstName:firstname,
        LastName:lastname,
        Email:email,
        Password:password,
        PhoneNumber:phonenumber,
        Company:company,
        Address1:address1,
        Address2:address2,
        City:city,
        State:state,
        Pincode:pincode,
        Country:country
      }).then(()=>{
        formData.append('email', email);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setPhoneNumber('');
        setCompany('');
        setAddress1('');
        setAddress2('');
        setCity('');
        setState('');
        setPincode('');
        //setCountry('');
        setError('');
        //alert(1);

        formData.append('ajax_type', 'json');
        formData.append('action', 'sendmil');

        //alert(baseURL);
        axios.post(baseURL, formData,h).then(function(response)
                    {
          alert("You have successfully registered");
        });
        //alert(3);
        //React.setSignInModalShow(false);
        //navigate('/login');
        auth.signOut().then(function() {
        // Sign-out successful.
        }).catch(function(error) {
        // An error happened.
        });
         props.onHide();
      }).catch(err=>setError(err.message));
    }).catch(err=>setError(err.message));
  }


    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton className='p-4' style={{'zIndex':1}}>
          <Modal.Title id="contained-modal-title-vcenter">
          <h3 className='my-0'>Registration</h3>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>
        <Form id="registrationform" noValidate onSubmit={Signup} className="registration-form w-100 m-auto">


        {error && <><div className="row m2 p2" style={{'color':'red'}}>{error}</div><br /></>}
          {isfname &&<div className="row m2 p2" style={{'color':'red'}}>{isfname}</div>}
          {islname &&<div className="row m2 p2" style={{'color':'red'}}>{islname}</div>}
          {isemail &&<div className="row m2 p2" style={{'color':'red'}}>{isemail}</div>}
          {isphonenumber &&<div className="row m2 p2" style={{'color':'red'}}>{isphonenumber}</div>}
          {iscity &&<div className="row m2 p2" style={{'color':'red'}}>{iscity}</div>}
          {ispincode &&<div className="row m2 p2" style={{'color':'red'}}>{ispincode}</div>}


            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 py-3" style={{'height':'500px','overflowX':'hidden','overflowY':'auto'}}>

            <br />
                    <FloatingLabel
                            controlId="regFirstName"
                            label="First Name*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="text" placeholder="First Name" name="firstname" defaultValue={firstname} onChange={(e)=>setFirstName(e.target.value)} value={firstname} onKeyUp={numericOdds} />
                    </FloatingLabel>

                    <FloatingLabel
                            controlId="regLastName"
                            label="Last Name*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="text" placeholder="Last Name" value={lastname} onChange={(e)=>setLastName(e.target.value)} value={lastname} name="lastname"  onKeyUp={numericOdds} />
                    </FloatingLabel>

                    <FloatingLabel
                            controlId="regEmail"
                            label="Email ID*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="email" placeholder="emailid@something.com" onChange={(e)=>setEmail(e.target.value)} value={email} name="email"  onKeyUp={numericOdds} autoComplete="off" />
                    </FloatingLabel>

                    <FloatingLabel
                            controlId="regPassword"
                            label="Password*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} value={password} name="password"  onKeyUp={numericOdds}  autoComplete="off"/>
                    </FloatingLabel>


                    <FloatingLabel
                            controlId="regPhoneNumber"
                            label="Phone Number*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="number" placeholder="+__-____ ____" value={phonenumber} onChange={(e)=>setPhoneNumber(e.target.value)} value={phonenumber} name="phonenumber" onKeyUp={numericOdds} />
                    </FloatingLabel>

                    <FloatingLabel
                            controlId="regCompanyName"
                            label="Company Name"
                            className="mb-3 col"
                            >
                        <Form.Control required type="text" defaultValue={company} placeholder="Company Name" onChange={(e)=>setCompany(e.target.value)} value={company} />
                    </FloatingLabel>

                    <Form.Label className="fw-bold text-start d-block text-secondary">Address: </Form.Label>
                    <FloatingLabel
                            controlId="regLine1"
                            label=" "
                            className="mb-3 col"
                            >

                    </FloatingLabel>
                    <FloatingLabel
                            controlId="regLine1"
                            label="Line 1*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="text" placeholder="Line 1" value={address1} onChange={(e)=>setAddress1(e.target.value)} value={address1} />
                    </FloatingLabel>
                    <FloatingLabel
                            controlId="regLine2"
                            label="Line 2"
                            className="mb-3 col"
                            >
                        <Form.Control  type="text" placeholder="Line 2" value={address2} onChange={(e)=>setAddress2(e.target.value)} value={address2} />
                    </FloatingLabel>

                    <FloatingLabel
                            controlId="regCity"
                            label="City*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="text" name="city" placeholder="City" onChange={(e)=>setCity(e.target.value)} value={city} onKeyUp={numericOdds} />
                    </FloatingLabel>
                    <div className="mb-3 col form-floating"><select className="form-control" required placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)} value={country} >
                        <option value="USA">USA </option>
                        </select>
                        </div>
                    <div className="mb-3 col form-floating">
                    <select className="form-control" required placeholder="State"
                        onChange={(e)=>setState(e.target.value)} value={state} value={state} >
                        <option value="">Select State</option>
                        { stlist.map((st) =>
                        <option value={st.id}>{st.title}</option>
                      )}

                        </select></div>
                    <FloatingLabel
                            controlId="regPinCode"
                            label="Pin or Zip Code*"
                            className="mb-3 col"
                            >
                        <Form.Control required type="number" placeholder="Pin or Zip Code" onChange={(e)=>setPincode(e.target.value)} value={pincode} name="zip" onKeyUp={numericOdds} />
                    </FloatingLabel>

            </div>
                    <div className="wrapper pe-0">
                        <button type="submit" className="w-auto btn btn-primary mx-auto d-block " >Submit form</button>
                    </div>
            {/* <div className="wrapper pe-0">
                <Button type="submit" className="w-auto btn btn-primary mx-auto d-block">Submit form</Button>
            </div>*/}
        </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

export const Navbar = ({ user }) => {

    const [signinModalShow, setSignInModalShow] = React.useState(false);
    const [registerModalShow, setRegisterModalShow] = React.useState(false);
    const [myaccModalShow, setMyAccModalShow] = React.useState(false);
    const [myprModalShow, setMyPrModalShow] = React.useState(false);

    const { shoppingCart, dispatch, totalPrice, totalQty } = useContext(CartContext);

    //sconst { totalQty } = useContext(CartContext);
    const logout = (user) => {
      var userid = '';
      auth.onAuthStateChanged(user => {
          if (user) {
            userid = user.uid;
            //alert(userid);
          }
        });


      auth.signOut().then(function() {
        //alert(1);
        //alert(userid);
        db.collection('cartdata').doc(userid).set({
          shoppingCart: localStorage.getItem("shoppingCart")?JSON.parse(localStorage.getItem("shoppingCart")):[],
          totalPrice: parseInt((localStorage.getItem("totalPrice")?localStorage.getItem("totalPrice"):0)),
          totalQty: parseInt((localStorage.getItem("totalQty")?localStorage.getItem("totalQty"):0))
        }).then(()=>{
        /*  localStorage.setItem("shoppingCart",JSON.stringify([]));
          localStorage.setItem("totalQty", 0);
          localStorage.setItem("totalPrice", 0);*/


        }).catch
        (err=>console.log(err.message));

        dispatch({ type: 'EMPTY' });
      // Sign-out successful.
    }).catch(function(error) { //alert(error);
      // An error happened.
      });
    }

    return (
      <div>
      <div className="header-top">
            <div className="container">
                <div className="header-left">
                    <p className="welcome-msg">Welcome to VAOFD - Food Products</p>
                </div>
                <div className="header-right">
                    {/*<div className="dropdown">
                        <a href="#currency">USD</a>
                        <div className="dropdown-box">
                            <a href="#USD">USD</a>
                            <a href="#EUR">EUR</a>
                        </div>
                    </div>*/}

                    <div className="dropdown">
                        <a href="#language"><img src={engflag} alt="ENG Flag" width="14"
                                height="8" className="dropdown-image" /> ENG</a>
                        <div className="dropdown-box">
                            <a href="#ENG">
                                <img src={engflag} alt="ENG Flag" width="14" height="8"
                                    className="dropdown-image" />
                                ENG
                            </a>
                            <a href="#FRA">
                                <img src={fraflag} alt="FRA Flag" width="14" height="8"
                                    className="dropdown-image" />
                                FRA
                            </a>
                        </div>
                    </div>

                    <span className="divider d-lg-show"></span>
                    <a href="blog.html" className="d-lg-show">Blog</a>
                    <a href="contact-us.html" className="d-lg-show">Contact Us</a>


                    {user &&
                    <a href="javascripit:void(0)" className="d-lg-show" onClick={() => setMyAccModalShow(user)}>My Account</a>
                    }
                    {!user &&
                    <span className="d-lg-show login sign-in" role="button" onClick={() => setSignInModalShow(true)}>
                        <i className="w-icon-account"></i>Sign In
                    </span> }
                    {!user && <span className="delimiter d-lg-show">/</span>}
                    {!user && <div className="ml-0 d-lg-show login register" role="button" onClick={() => setRegisterModalShow(true)}>Register</div>}
                    <span><Link to="/cartproducts" className='navlink'><Icon icon={cart} /></Link></span>
          <span className='no-of-products'>({totalQty})</span>
                    {user &&
                      <span className="d-lg-show login sign-in" role="button">
                          <i className="w-icon-account" onClick={() => setMyPrModalShow(user)}></i>{user}

                          <span className="d-lg-show login sign-in" role="button" onClick={() => logout()}>
                              Logout
                          </span>

                      </span>}
                </div>
            </div>
        </div>

        <SignInModal show={signinModalShow} onHide={() => setSignInModalShow(false)}  />
        <RegisterModal show={registerModalShow} onHide={() => setRegisterModalShow(false)} />
          <MyAccModal show={myaccModalShow} onHide={() => setMyAccModalShow(false)} />
          <MyPrModal show={myprModalShow} onHide={() => setMyPrModalShow(false)} />
        <div className="header-middle">
            <div className="container">
                <div className="header-left mr-md-4">
                    <a href="#" className="mobile-menu-toggle  w-icon-hamburger" aria-label="menu-toggle">
                    </a>
                    <a href="/" className="logo ml-lg-0">
                        <img src="https://vaofd.com/static/media/logo-VOAFD.7a1b0b12af8a63fe0b1a.jpg" alt="logo" width="120" />
                    </a>
                    <form method="get" action="#"
                        className="header-search hs-expanded hs-round d-none d-md-flex input-wrapper">
                        <div className="select-box">
                            <select id="category" name="category">
                                <option value="">All Categories</option>
                                <option value="4">Millets</option>
                                <option value="5">Jowar</option>
                                <option value="6">Dried Millet</option>
                                <option value="7">Millet Flours</option>
                                <option value="8">Hulled Millet</option>
                                <option value="9">Spray Millet</option>
                                <option value="10">Red Millet</option>
                                <option value="11">Organic Millet</option>
                                <option value="12">Pearl Millet</option>
                            </select>
                        </div>
                        <input type="text" className="form-control" name="search" id="search" placeholder="Search in..."
                            required />
                        <button className="btn btn-search" type="submit"><i className="w-icon-search"></i>
                        </button>
                    </form>
                </div>
                <div className="header-right ml-4">
                    <div className="header-call d-xs-show d-lg-flex align-items-center">
                        <a href="tel:#" className="w-icon-call"></a>
                        <div className="call-info d-lg-show">
                            <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                                <a href="mailto:#" className="text-capitalize">Live Chat</a> or :
                            </h4>
                            <a href="tel:#" className="phone-number font-weight-bolder ls-50">+1-678-899-6769</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="header-bottom sticky-content fix-top sticky-header has-dropdown">
            <div className="container">
                <div className="inner-wrap">
                    <div className="header-left">
                        <nav className="main-nav">
                            <ul className="menu active-underline">
                                <li className="active">
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="shop-banner-sidebar.html">Shop</a>
                                </li>
                                <li>
                                    <a href="vendor-dokan-store.html">Vendor</a>

                                </li>
                                <li>
                                    <a href="blog.html">About us</a>
                                </li>
                                <li>
                                    <a href="blog.html">Blog</a>
                                   </li>
                                   </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <a href="#" className="d-xl-show"><i className="w-icon-map-marker mr-1"></i>Track Order</a>
                        <a href="#"><i className="w-icon-sale"></i>Daily Deals</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
